import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Navlinks = () => (
  <ul>
    <li>
      <AniLink
        cover
        direction="left"
        bg="#fff"
        duration={1}
        className="navbar-item"
        to="/sobre"
      >
        Sobre
      </AniLink>
    </li>
    <li>
      <AniLink
        className="navbar-item"
        cover
        to="/equipe"
        direction="left"
        duration={1}
        bg="#fff"
      >
        Equipe
      </AniLink>
    </li>
    <li>
      <AniLink
        className="navbar-item"
        cover
        to="/projetos"
        direction="left"
        duration={1}
        bg="#fff"
      >
        Projetos
      </AniLink>
    </li>
    <li>
      <AniLink
        className="navbar-item"
        cover
        to="/publicacoes"
        direction="left"
        duration={1}
        bg="#fff"
      >
        Publicações
      </AniLink>
    </li>
    <li>
      <AniLink
        className="navbar-item"
        cover
        to="/parceiros"
        direction="left"
        duration={1}
        bg="#fff"
      >
        Parceiros
      </AniLink>
    </li>
    <li>
      <AniLink
        className="navbar-item"
        cover
        to="/galeria"
        direction="left"
        duration={1}
        bg="#fff"
      >
        Galeria
      </AniLink>
    </li>
    <li>
      <AniLink
        className="navbar-item"
        cover
        to="/contato"
        direction="left"
        duration={1}
        bg="#fff"
      >
        Contato
      </AniLink>
    </li>
  </ul>
)
export default Navlinks
